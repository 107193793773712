import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'

const routes = [
  {
    path: '/:locale',
    beforeEnter: (to, from, next) => {
      const locale = to.params.locale
      const supportedLocales =
        process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',')
      if (!supportedLocales.includes(locale)) {
        return next(process.env.VUE_APP_I18N_LOCALE)
      }
      store.state.locale = locale // watching in App.vue
      return next()
    },
    component: {
      template: '<router-view></router-view>'
    },
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('../views/Home.vue'),
        meta: {
          titlePrefix: ''
        }
      },
      {
        path: 'blog',
        name: 'Blog',
        component: () => import('../views/Blog.vue'),
        meta: {
          titlePrefix: 'Blog'
        }
      },
      {
        path: 'projects',
        name: 'Projects',
        component: () => import('../views/Projects.vue'),
        meta: {
          titlePrefix: 'Projects'
        }
      },
      {
        path: 'project/:project_id/:project_title',
        name: 'Project',
        component: () => import('../views/Project.vue'),
        meta: {
          titlePrefix: 'Project'
        }
      },
      {
        path: 'tools',
        name: 'Tools',
        component: () => import('../views/Tools.vue'),
        children: [
          {
            path: '',
            name: 'ToolsHome',
            component: () => import('../views/Tools/ToolsHome.vue'),
            meta: {
              titlePrefix: 'Tools'
            }
          },
          {
            path: 'randomizer',
            name: 'Randomizer',
            component: () => import('../views/Tools/Randomizer.vue'),
            meta: {
              titlePrefix: 'Randomizer | Tools'
            }
          },
          {
            path: 'ip-locator',
            name: 'IPLocator',
            component: () => import('../views/Tools/IPLocator.vue'),
            meta: {
              titlePrefix: 'IP Locator | Tools'
            }
          },
          {
            path: 'color-picker',
            name: 'ColorPicker',
            component: () => import('../views/Tools/ColorPicker.vue'),
            meta: {
              titlePrefix: 'Color Picker | Tools'
            }
          }
        ]
      }
    ]
  },
  {
    path: '/:catchAll(.*)',
    redirect () {
      return process.env.VUE_APP_I18N_LOCALE
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from) => {
  // allow direct access for all pages if it is in debug mode
  if (store.state.isDebug) return true

  if (to.name === undefined) {
    // not in mapping
    return { name: 'Home' }
  }
})

export default router
