<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
  watch: {
    $route: {
      immediate: true,
      handler (to) {
        var title = 'Basic Law - Full-Stack Web Developer'
        var titlePrefix = to.meta.titlePrefix
        document.title =
          titlePrefix === undefined || titlePrefix === ''
            ? title
            : titlePrefix + ' | ' + title
      }
    },
    '$store.state.locale': function () {
      var locale = this.$store.state.locale
      if (
        !process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',').includes(locale)
      ) {
        locale = process.env.VUE_APP_I18N_LOCALE
      }
      if (this.$i18n.locale !== locale) {
        var path = location.href.replace(location.origin, '')
        path = path.replace('/' + this.$i18n.locale, '/' + locale)
        this.$i18n.locale = locale
        this.$router.push({ path: path })
      }
    }
  },
  methods: {
    safariHacks () {
      const windowsVH = window.innerHeight / 100
      document.querySelector('*').style.setProperty('--vh', windowsVH + 'px')
    }
  },
  created () {
    window.addEventListener('resize', this.safariHacks)
  },
  mounted () {
    this.safariHacks()
  }
}
</script>

<style lang="scss">
@import url('./assets/main.scss');
@import '@/assets/variables.scss';

html,
body {
  background: $dark-grey;
  color: $beige;
}
</style>
