import { createStore } from 'vuex'

export default createStore({
  state: {
    isDebug: false,
    locale: 'en',
    endpoint: 'https://cms.basicl.aw/wp-json/v1/'
  },
  mutations: {},
  actions: {},
  modules: {}
})
